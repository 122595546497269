@import "~antd/dist/antd.css";

/* Rules set in here will apply to all children of the <App> container as well. */
/* --- base styles --*/
.full {
	width: "100%" !important;
}
.normal {
	font-weight: 400;
	font-size: 0.91rem;
}
.semi-bold {
	font-weight: 500;
}
.bold {
	font-weight: 600;
}
.extraBold {
	font-weight: 700 !important;
}
.thick {
	font-weight: 800;
}
.mb-1 {
	margin-bottom: 4px !important;
}
.mb-10 {
	margin-bottom: 20px;
}

.mb-5 {
	margin-bottom: 10px;
}

.py-4 {
	padding: 8px 4px;
}

.capitalize {
	text-transform: capitalize;
}

.flex {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
}

.text-md {
font-size: 1.35rem !important;
}

.flex-start {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.grid-small {
	display: grid;
	grid-template-columns: 110px auto;
	column-gap: 2rem;
}

.grid-column {
	display: grid;
	row-gap: 0.5rem;
	column-gap: 2rem;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #585c64;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.site-layout-content {
	min-height: 280px;
	padding: 24px 30px;
	background: #fff;
}

/* Classnames */
.centerImages {
	text-align: center;
}

.carouselDots > li button {
	background: #444 !important;
}

/* Carousel */
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
	font-size: inher;
	color: currentColor;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
	left: 10px;
	z-index: 2;
	color: #000;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
	right: 10px;
	z-index: 2;
	color: #000;
}

.ant-carousel .slick-arrow {
	opacity: 0;
	transition: opacity 0.3s;
}

.ant-carousel:hover .slick-arrow {
	opacity: 1;
}
.content {
	min-height: 300px;
}
.image-container,
.content {
	border: none;
	margin-top: 10px;
}
.image-container {
	height: auto;
}
.ant-carousel .ant-image,
.image-container .ant-image {
	height: 100%;
}

.image-container img {
	object-fit: cover;
}

.ant-carousel img {
	object-fit: cover;
}

.ant-btn {
}
@media screen and (min-width: 992px) {
	.site-layout-content {
		padding: 24px 80px;
	}
	.mb-10 {
		margin-bottom: 40px;
	}
	.mb-5 {
		margin-bottom: 15px;
	}
	.py-4 {
		padding: 16px 8px;
	}
}

